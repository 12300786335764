<script setup lang="ts">
const { isSuperadmin, isAdmin } = useUserState()
</script>

<template>
  <v-list>
    <v-divider />
    <v-list-item title="Briefings" prepend-icon="$mdiListbox" to="/briefings" />
    <v-divider />
    <v-list-item title="Productos" prepend-icon="$mdiDashboard" to="/products" />
    <v-divider v-if="isSuperadmin" />
    <v-list-item v-if="isSuperadmin" title="SaaS Clientes" prepend-icon="$mdiOffice" to="/saas-clientes" />
    <v-divider />
    <v-list-item title="Recomendaciones" prepend-icon="$mdiLightbulbOnOutline" to="/insights" />
    <v-divider v-if="isSuperadmin || isAdmin" />
    <v-list-item v-if="isSuperadmin || isAdmin" title="Parámetros" prepend-icon="$mdiProgressCheck" to="/parameters" />
    <v-divider />
    <v-list-item v-if="isSuperadmin" title="Analytics" prepend-icon="$mdiAnalytics" to="/analytics" />
    <v-divider v-if="isSuperadmin" />
  </v-list>
</template>
