<script setup lang="ts">
const { isSuperadmin, isAdmin } = useUserState()
</script>

<template>
  <v-list class="!p-0">
    <v-divider v-if="isSuperadmin" />
    <v-list-group v-if="isSuperadmin">
      <template v-slot:activator="{ props }">
        <v-list-item
          v-bind="isSuperadmin ? props : null"
          :disabled="!isSuperadmin"
          prepend-icon="$mdiCog"
          title="Configuración"
        />
      </template>
      <v-divider />
      <v-list-item title="Productos" prepend-icon="$mdiProducts" :to="isSuperadmin ? '/config/products' : '/'" :disabled="!isSuperadmin" />
<!--      <v-divider />
      <v-list-item title="Planes" prepend-icon="$mdiPlaylistCheck" :to="isSuperadmin ? '/config/plans' : '/'" :disabled="!isSuperadmin" />-->
      <v-divider />
      <v-list-item title="Empresas" prepend-icon="$mdiOfficeOutline" :to="isSuperadmin ? '/config/companies' : '/'" :disabled="!isSuperadmin" />
      <v-divider />
<!--      <v-list-item title="Clientes" prepend-icon="$mdiOffice" :to="isSuperadmin ? '/config/customers' : '/'" :disabled="!isSuperadmin" />
      <v-divider />-->
      <v-list-item title="Documentación" prepend-icon="$mdiDocuments" :to="isSuperadmin ? '/config/documentation' : '/'" :disabled="!isSuperadmin && !isAdmin" />
      <v-divider />
      <v-list-item title="Usuarios" prepend-icon="$mdiAccount" :to="isSuperadmin ? '/config/users' : '/'" :disabled="!isSuperadmin" />
    </v-list-group>
  </v-list>
</template>